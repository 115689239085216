export const config = {

    email_error: "support-nomade@clevacances.com",

    versions : [{
        id: "2.0.1",
        name: "version stable"
    }],

    group_visit: {
        loc: { version: 4, name: "Location", theme: false, validable: true},
        ch: { version: 4, name: "Chambre d'hôtes", theme: false, validable: true },
        inso: { version: 2, name: "Insolite", theme: false, validable: true },
        hll: { version: 2, name: "Habitation légère de loisirs", theme: false, validable: true },
        ges: { version: 1, name: "Gîte d'étape et de séjour", theme: false, validable: true },
        th_ski: { version: 1, name: "Au pied des pistes", theme: true, validable: false },
        th_pch: { version: 1, name: "Destination pêche", theme: true, validable: true, label_lie: "th_apch" },
        th_vin: { version: 1, name: "Séjour oenologique", theme: true, validable: true, label_lie: "th_vigdec" },
        th_iti: { version: 1, name: "Itinérance et courte durée", theme: true, validable: false },
        th_pde: { version: 1, name: "Les pieds dans l’eau", theme: true, validable: false },
        th_rm: { version: 1, name: "Nuit romantique", theme: true, validable: false },
        th_tltr: { version: 1, name: "Séjour professionnel", theme: true, validable: false },
        th_spa: { version: 1, name: "Spa & Bien Être", theme: true, validable: false },
        th_tha: { version: 1, name: "Thermalisme et curiste", theme: true, validable: false},
        th_frm: { version: 1, name: "Vacances à la Ferme", theme: true, validable: true, label_lie: "th_bfrm" },//lié à label: "th_bfrm"
        th_eco: { version: 1, name: "Vacances écologiques", theme: true, validable: true, label_lie: "th_clev", label_lie2: "th_ecolabel" },
        th_mot: { version: 1, name: "Vacances à moto", theme: true, validable: true, label_lie: "th_acmotard" },
        th_velo: { version: 1, name: "Vacances à vélo", theme: true, validable: true, label_lie : "th_avelo" },
        th_chv: { version: 1, name: "Vacances équestres", theme: true, validable: true, label_lie : "th_chv" },
        th_fml: { version: 1, name: "Vacances famille & amis", theme: true, validable: true, label_lie : 'th_vacfamami' },
        th_st: { version: 1, name: "Vacances nature", theme: true, validable: true, label_lie: "th_randoac" },
        th_vue: { version: 1, name: "Vue panoramique", theme: true, validable: true }
    },

    visits_types:[
        {id: 'vst', name: "Visite de labellisation", keypath: 'vst'},
        {id: 'vstre', name: "Visite quinquennale", keypath: 'vstre'},
        {id: 'vstcs', name: "Visite conseil", keypath: 'vstcs'},
        {id: 'vstct', name: "Visite de contrôle", keypath: 'vstct'},
        {id: 'vstte', name: "Visite de test", keypath: 'vstte'},
        {id: 'vs4themes', name: "Visite spéciale pour thématique", keypath: 'vs4themes', forvisit : false},
        /*{id: 'undefined', name: "Indéfini", keypath: 'undefined', forvisit : false},*/
        {id: 'vstarch', name: "Visite archive", keypath: 'vstarch', forvisit : false},
        /*{id: 'dev', name: "[TEST ADMIN] Visite pré-remplie", keypath: 'vstct'}*/
    ],

    revisite: {
        mois: 60 //tous les 4 ans
    },
    periodUpdate: {
        mois: 3 //possibilité de modifier une visite scellée durant 3 mois
    },

    apiData: {

        dirServerFiles : 'uploadsvisitsfiles/',
        loginpass: '&login=testnomade@clevacances.com&pass=testnomade',
        passBackDoor: 'cle_pass31',
        samlGenereLink : 'saml/getidplink',
        entities:{

            users: {
                //retourne le user si le login et pass(non encrypté) sont OK
                //retourne l'URL SAML qui pointe vers le fournisseur d'identité qui lui retournera (si le user s'authentifie bien) les détails du user
                    check: 'users.json?fct=cnx',
                //retourne la liste des techniciens des mêmes térritoires que le user login/pass
                    getList: 'users.json?fct=list',
            },
            props: {
                //retourne la liste des proprietaires des mêmes térritoires que le user login/pass
                    getList: 'props.json?fct=list', //&lastsync=TIMESTAMP
            },
            locs: {
                //retourne la liste des biens des mêmes térritoires que le user login/pass
                    getList: 'locs.json?fct=list',
            },
            visits: {
                //retourne la liste des visites des mêmes térritoires que le user login/pass
                    getList: 'visits.json?fct=list',// optionnel : depuis la dernière synchro -> &lastsync=2021-07-25
                //retourne une visite, indiquer son UID dans l'url
                    get:'visits.json?fct=get', //&visit_uid=UID
                    getAgrement: 'visits.json?fct=getAgrement',
                //Tous les paramètres en GET et le data en POST
                    new: 'visits.json?fct=create', //&user=ID
                    update: 'visits.json?fct=update', //&visit_uid=UID&date_update=TIMESTAMP&user=ID
                    delete: 'visits.json?fct=delete',  //&visit_uid=UID&user=ID,
                    check: 'visits.json?fct=checkList',
                    archives: 'visits.json?fct=listArchive',
                    sceller: 'visits.json?fct=sceller', //&visit_uid=UID&user=ID&user_uid=UID
                    theme_delete: 'visits.json?fct=theme_delete',
                    visit_files_get: 'visits.json?fct=visit_files_get',
                    visit_file_add: 'visits.json?fct=visit_file_add',
                    visit_file_delete: 'visits.json?fct=visit_file_delete',
            },
        },
        syncFrequency: 60 // 5 minutes (60 * 5)
    },
    dbNomade: {
        name: 'Nomade',
        version: 1.1,
        stores: {
            app_params: 'name, value',
            visits: 'uid, version, loc_hlo, type_bien, type_visite, date_visite, date_create, date_update, scelle, user_uid, user_email, user_tel, user_gender, user_lastname, user_firstname, user_adress1, user_adress2, user_adress3, user_commune, user_postal_code, cpt_rendu, cles_calcul, cles',
            visits_content: '[uid+group_visite], uid, group_visite, content, valide, label_lie, date_fin_label, label_lie2, date_fin_label2',
            props: 'uid, email, gender, lastname, firstname, state, commune, postal_code, adresse, adress2, adress3, tel, tel_work, mobile, photo',
            users: 'uid, email, tel, lastname, firstname, level, deps, pass, adress1, adress2, adress3, commune, postal_code',
            locs: 'loc_hlo, agr, name, state, date_visite, type, nature, capacity, demo, commune, postal_code, adresse, adress2, adress3, departement, structure_id, structure_name, props'
        }
    },

    autorisations: {
        sceller: {
            level: 70
        },
        clesEstimees: {
            "70": 3,
            "100": 5
        },
        duplication:{
            date_visite: 1652223600 // limite au 11/05/2022
        }
    },

    urlCdnPhotoProps: 'https://www.clevacances.com/storage/avatar/',

    maxInactivityDelay: 120,
    swRefreshDelay: 2,
    saveCurrentVisiteDelay: 10, // en secondes

    deps:{
        "0"	:{nom:"Sans département", pre_de:"'", pre_dans:""},
        "01"	:{nom:"Ain",pre_de:"de l'", pre_dans:"dans l'"},
        "001"	:{nom:"Ain",pre_de:"de l'", pre_dans:"dans l'"},
        "02"	:{nom:"Aisne",pre_de:"de l'", pre_dans:"dans l'"},
        "002"	:{nom:"Aisne",pre_de:"de l'", pre_dans:"dans l'"},
        "03"	:{nom:"Allier",pre_de:"de l'", pre_dans:"dans l'"},
        "003"	:{nom:"Allier",pre_de:"de l'", pre_dans:"dans l'"},
        "04"	:{nom:"Alpes de Haute Provence",pre_de:"d'", pre_dans:"dans les"},
        "004"	:{nom:"Alpes de Haute Provence",pre_de:"d'", pre_dans:"dans les"},
        "05"	:{nom:"Hautes-Alpes",pre_de:"de", pre_dans:"dans les"},
        "005"	:{nom:"Hautes-Alpes",pre_de:"de", pre_dans:"dans les"},
        "06"	:{nom:"Alpes Maritimes",pre_de:"des", pre_dans:"en"},
        "006"	:{nom:"Alpes Maritimes",pre_de:"des", pre_dans:"en"},
        "07"	:{nom:"Ardèche",pre_de:"d'", pre_dans:"en"},
        "007"	:{nom:"Ardèche",pre_de:"d'", pre_dans:"en"},
        "08"	:{nom:"Ardennes",pre_de:"des", pre_dans:"dans les"},
        "008"	:{nom:"Ardennes",pre_de:"des", pre_dans:"dans les"},
        "09"	:{nom:"Ariège",pre_de:"de l'", pre_dans:"en"},
        "009"	:{nom:"Ariège",pre_de:"de l'", pre_dans:"en"},
        "010"	:{nom:"Aube",pre_de:"de l'", pre_dans:"dans l'"},
        "011"	:{nom:"Aude",pre_de:"de l'", pre_dans:"dans l'"},
        "012"	:{nom:"Aveyron",pre_de:"de l'", pre_dans:"en"},
        "013"	:{nom:"Bouches du Rhone",pre_de:"des", pre_dans:"dans les"},
        "014"	:{nom:"Calvados",pre_de:"du", pre_dans:"dans le"},
        "015"	:{nom:"Cantal",pre_de:"du", pre_dans:"dans le"},
        "016"	:{nom:"Charente",pre_de:"de la", pre_dans:"en"},
        "017"	:{nom:"Charente Maritime",pre_de:"de la ", pre_dans:"en"},
        "018"	:{nom:"Cher",pre_de:"du", pre_dans:"dans le"},
        "019"	:{nom:"Corrèze",pre_de:"de", pre_dans:"en"},
        "021"	:{nom:"Cote d'Or",pre_de:"de la", pre_dans:"en"},
        "022"	:{nom:"Côtes d'Armor",pre_de:"des", pre_dans:"dans les"},
        "023"	:{nom:"Creuse",pre_de:"de la", pre_dans:"en"},
        "024"	:{nom:"Dordogne",pre_de:"de la", pre_dans:"en"},
        "025"	:{nom:"Doubs",pre_de:"du", pre_dans:"dans le"},
        "026"	:{nom:"Drôme",pre_de:"de la", pre_dans:"dans la"},
        "027"	:{nom:"Eure",pre_de:"de l'", pre_dans:"dans l'"},
        "028"	:{nom:"Eure et Loir",pre_de:"de l'", pre_dans:"en"},
        "029"	:{nom:"Finistère",pre_de:"du", pre_dans:"dans le"},
        "030"	:{nom:"Gard",pre_de:"du", pre_dans:"dans le"},
        "031"	:{nom:"Haute-Garonne",pre_de:"de", pre_dans:"en"},
        "032"	:{nom:"Gers",pre_de:"du", pre_dans:"dans le"},
        "033"	:{nom:"Gironde",pre_de:"de la", pre_dans:"en "},
        "034"	:{nom:"Hérault",pre_de:"de l'", pre_dans:"dans l'"},
        "035"	:{nom:"Haute Bretagne Ille et Vilaine",pre_de:"de", pre_dans:"en"},
        "036"	:{nom:"Indre",pre_de:"de l'", pre_dans:"dans l'"},
        "037"	:{nom:"Indre-et-Loire",pre_de:"de l'", pre_dans:"dans l'"},
        "038"	:{nom:"Isère",pre_de:"de l'", pre_dans:"en"},
        "039"	:{nom:"Jura",pre_de:"du", pre_dans:"dans le"},
        "040"	:{nom:"Landes",pre_de:"des", pre_dans:"dans les"},
        "041"	:{nom:"Loir et Cher",pre_de:"du", pre_dans:"dans le"},
        "042"	:{nom:"Loire",pre_de:"de la", pre_dans:"dans la"},
        "043"	:{nom:"Haute-Loire",pre_de:"de la", pre_dans:"dans la"},
        "044"	:{nom:"Loire Atlantique",pre_de:"de la", pre_dans:"dans la"},
        "045"	:{nom:"Loiret",pre_de:"du", pre_dans:"dans le"},
        "046"	:{nom:"Lot",pre_de:"du", pre_dans:"dans le"},
        "047"	:{nom:"Lot et Garonne",pre_de:"du", pre_dans:"dans le"},
        "048"	:{nom:"Lozère",pre_de:"de la", pre_dans:"en"},
        "049"	:{nom:"Maine et Loire",pre_de:"du", pre_dans:"dans le"},
        "050"	:{nom:"Manche",pre_de:"de la", pre_dans:"dans la"},
        "051"	:{nom:"Marne",pre_de:"de la", pre_dans:"dans la"},
        "052"	:{nom:"Haute Marne",pre_de:"de la", pre_dans:"dans la"},
        "053"	:{nom:"Mayenne",pre_de:"de la", pre_dans:"dans la"},
        "054"	:{nom:"Meurthe et Moselle",pre_de:"de", pre_dans:"en"},
        "055"	:{nom:"Meuse",pre_de:"de la", pre_dans:"dans la"},
        "056"	:{nom:"Morbihan",pre_de:"du", pre_dans:"dans le"},
        "057"	:{nom:"Moselle",pre_de:"de", pre_dans:"en"},
        "058"	:{nom:"Nièvre",pre_de:"de la", pre_dans:"dans la"},
        "059"	:{nom:"Nord",pre_de:"du", pre_dans:"dans le"},
        "060"	:{nom:"Oise",pre_de:"de l'", pre_dans:"dans l'"},
        "061"	:{nom:"Orne",pre_de:"de l'", pre_dans:"dans l'"},
        "062"	:{nom:"Pas de Calais",pre_de:"du", pre_dans:"dans le"},
        "063"	:{nom:"Puy-de-Dôme",pre_de:"du", pre_dans:"dans le"},
        "064"	:{nom:"Pyrénées-Atlantiques",pre_de:"des", pre_dans:"dans les"},
        "065"	:{nom:"Hautes-Pyrénées",pre_de:"des", pre_dans:"dans les"},
        "066"	:{nom:"Pyrénées-Orientales",pre_de:"des", pre_dans:"dans les"},
        "067"	:{nom:"Bas Rhin",pre_de:"du", pre_dans:"dans le"},
        "068"	:{nom:"Haut Rhin",pre_de:"du", pre_dans:"dans le"},
        "069"	:{nom:"Rhône",pre_de:"du", pre_dans:"dans le"},
        "070"	:{nom:"Haute Saône",pre_de:"de la", pre_dans:"dans la"},
        "071"	:{nom:"Saône et Loire",pre_de:"de", pre_dans:"en"},
        "072"	:{nom:"Sarthe",pre_de:"de la", pre_dans:"dans la"},
        "073"	:{nom:"Savoie",pre_de:"de la", pre_dans:"en"},
        "074"	:{nom:"Haute Savoie",pre_de:"de la", pre_dans:"en"},
        "075"	:{nom:"Paris",pre_de:"de", pre_dans:"‡"},
        "076"	:{nom:"Seine Maritime",pre_de:"de la", pre_dans:"en"},
        "077"	:{nom:"Seine et Marne",pre_de:"de la", pre_dans:"en"},
        "078"	:{nom:"Yvelines",pre_de:"des", pre_dans:"dans les"},
        "079"	:{nom:"Deux Sèvres",pre_de:"des", pre_dans:"dans les"},
        "080"	:{nom:"Somme",pre_de:"de la", pre_dans:"dans la"},
        "081"	:{nom:"Tarn",pre_de:"du", pre_dans:"dans le"},
        "082"	:{nom:"Tarn et Garonne",pre_de:"du", pre_dans:"dans le"},
        "083"	:{nom:"Var",pre_de:"du", pre_dans:"dans le"},
        "084"	:{nom:"Vaucluse",pre_de:"du", pre_dans:"dans le"},
        "085"	:{nom:"Vendée",pre_de:"de la", pre_dans:"en"},
        "086"	:{nom:"Vienne",pre_de:"de la", pre_dans:"dans la"},
        "087"	:{nom:"Haute Vienne",pre_de:"de la", pre_dans:"en"},
        "088"	:{nom:"Vosges",pre_de:"des", pre_dans:"dans les"},
        "089"	:{nom:"Yonne",pre_de:"de l'", pre_dans:"dans l'"},
        "090"	:{nom:"Territoire de Belfort",pre_de:"du", pre_dans:"dans le"},
        "091"	:{nom:"Essonne",pre_de:"de l'", pre_dans:"en"},
        "092"	:{nom:"Hauts de Seine",pre_de:"des", pre_dans:"dans les"},
        "093"	:{nom:"Seine St Denis",pre_de:"de la", pre_dans:"en"},
        "094"	:{nom:"Val de Marne",pre_de:"du", pre_dans:"dans le"},
        "095"	:{nom:"Val d'Oise",pre_de:"du", pre_dans:"dans le"},
        "02A"  :{nom:"Corse du Sud",pre_de:"de", pre_dans:"en"},
        "02B"  :{nom:"Haute Corse",pre_de:"de", pre_dans:"en"},
        "201"  :{nom:"Corse du Sud",pre_de:"de", pre_dans:"en"},
        "202"  :{nom:"Haute Corse",pre_de:"de", pre_dans:"en"},
        "971"	:{nom:"Guadeloupe",pre_de:"de la", pre_dans:"en"},
        "972"	:{nom:"Martinique",pre_de:"de la", pre_dans:"en"},
        "973"	:{nom:"Guyane",pre_de:"de la", pre_dans:"en"},
        "974"	:{nom:"Ile de la Réunion",pre_de:"de l'", pre_dans:"l'"},
        "976"	:{nom:"Mayotte",pre_de:"de ", pre_dans:""},
        "978"	:{nom:"Saint Martin",pre_de:"de", pre_dans:""},
        "987"	:{nom:"Tahiti",pre_de:"de", pre_dans:""},
    }
};
switch (window.location.host) {
    case "localhost:3000" :
        config.apiData.domain = "http://api-nomade.local/";
        config.domain = 'http://localhost:3000/';
        break;
    case "v1-nomade-staging-clevacances.splitfire.fr" :
    case "nomade.preprod-clevacances.fr" :
        config.apiData.domain = "https://api-nomade.preprod-clevacances.fr/";
        config.domain = 'https://nomade.preprod-clevacances.fr/';
        break;
    default:
        config.apiData.domain = "https://api-nomade.clevacances.com/";
        config.domain = 'https://nomade.clevacances.com/';
        break;
}