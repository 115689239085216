import React, { Fragment, useEffect, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../config";
import VisitePdf from "../../pages/visitPdf";
import { getLocationByHLO } from "../../services/locationsService";
import { getProprioByUID, getGenderName } from "../../services/propriosService";
import { getDateLightFromTimestamp } from "../../services/utilities";
import { getVisiteHasContent, setSyncActive, getIsForVisite } from "../../services/visitesService";
import ButtonDeleteVisite from "../button-delete-visite";
import ButtonDuplicateVisite from "../button-duplicate-visite";
import ErrorBoundary from "../ErrorBoundary";
import ModalComment from "../modal-comment";
import VisiteTypeButton from "./visite-type-button";
import useAutorisations from "../../services/useAutorisations";
import { dbAdd, dbGet } from "../../services/indexedDBService";
import ButtonFiles from "../button-files";

const VisiteItem = ({visite}) => {

    const type = config.visits_types.find(t => t.id === visite.type_visite);
    const typeVisite = (type && type.name) || 'Indéfini';
    const cles = (visite.cles && visite.cles !== 0) ? visite.cles : visite.cles_calcul;
    const date = getDateLightFromTimestamp(visite.date_visite);
    const dateCreate = getDateLightFromTimestamp(visite.date_create, true);
    const dateUpdate = getDateLightFromTimestamp(visite.date_update, true);
    const [dateScelle, setDateScelle] = useState(null);
    // const userScelle = getUserById(visite.user_uid);
    const bien = getLocationByHLO(visite.loc_hlo);
    const agrement = bien ? bien.agr : null;

    const { canUpdateVisite, canDeleteVisite } = useAutorisations();

    const propsUids = (bien && bien.props) ? bien.props.split(',') : [];
    const props = propsUids.map(propUid => getProprioByUID(propUid));

    const [showCommentModal, setShowCommentModal] = useState(false);
    const [hasContent, setHasContent] = useState(true);

    // Vérifier si sur mobile pour lien pdf plutot que viewer pdf
    const isMobile = (navigator.maxTouchPoints > 0) || ('ontouchstart' in window);

    let forVisite = getIsForVisite(visite);
    // les visites thématiques doivent pouvoir être modifiables
    if( visite.type_visite === "vs4themes" ){forVisite=true;}

    const [vuid, setVuid] = useState('');
    const [pageArchiveExists, setPageArchiveExists] = useState(false);
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    useEffect(() => {

        if (visite.type_visite === 'vstarch' && isOnline ) {
            const vuidTemp = visite.uid.startsWith('vstarch') ? visite.uid.slice(7) : visite.uid;
            setVuid(vuidTemp);

            // URL à vérifier
            const url = `${config.apiData.domain}vstarch/visite-${vuidTemp}.html`;

            // Vérifier si la page existe
            fetch(url, { method: 'HEAD' })
                .then(response => {

                    if (response.ok) {
                        setPageArchiveExists(true);
                    } else {
                        setPageArchiveExists(false);
                    }
                })
                .catch(error => {
                    setPageArchiveExists(false);
                });
        }

        // flag pour interrompre les requêtes asynchrones
        // Quand le composant est démonté
        let mounted = true;

        if(mounted){

            getVisiteHasContent(visite.uid, visite.type_bien)
            .then(result => setHasContent(result));

            if(visite.scelle){

                // Mise à jour de la date scellée pour les
                // anciennes visites qui ont un booleen à la place
                if(typeof visite.scelle === 'boolean'){
                    dbGet('visits', visite.uid).then(result => {
                        result.scelle = visite.date_update;
                        dbAdd('visits', result).then(id => {
                            if(mounted){
                                setDateScelle(getDateLightFromTimestamp(visite.date_update, true));
                            }
                        })
                    });
                } else {
                    setDateScelle(getDateLightFromTimestamp(visite.scelle, true));
                }
            }
        }

        return () => {
            mounted = false;
        }

    }, [visite]);

    const openCommentModal = () => {
        setSyncActive(false);
        setShowCommentModal(true);
    }

    const onCommentModalclose = () => {
        setSyncActive(true);
        setShowCommentModal(false);
    }

    const tooltipVoir = (props) => (
        <Tooltip id="tooltip-voir-visite" {...props}>Voir cette visite</Tooltip>
    );

    const tooltipEdit = (props) => (
        <Tooltip id="tooltip-editer-visite" {...props}>Modifier cette visite</Tooltip>
    );

    const tooltipDuplicateToLate = (props) => (
        <Tooltip id="tooltip-duplicatetolate-visite" {...props}>Seules les visites créées après le 11/05/2022 sont duplicables</Tooltip>
    );

    const tooltipCompteRendu = (props) => (
        <Tooltip id="tooltip-compte-rendu-visite" {...props}>
            {(visite.cpt_rendu && visite.cpt_rendu !== '<p></p>') ? "Modifier le compte-rendu de cette visite" : "Rédiger un compte-rendu pour cette visite"}
        </Tooltip>
    );

    const tooltipPDF = (props) => (
        <Tooltip id="tooltip-compte-rendu-pdf" {...props}>
            Voir le dossier compte-rendu au format pdf.
        </Tooltip>
    );

    return(
        <div id={visite.uid} className="visite-item d-flex align-items-center justify-content-between border-bottom h-100">
            <div className="d-flex flex-wrap align-items-start">
                <div className="d-flex">
                    <div className="visite-id p-2">
                        <span className="uid">{visite.uid}</span>
                        <span className="visite-status">{visite.scelle && <i className="icon icon-lock text-danger fs-4"></i>}</span>
                    </div>
                    <div className="visite-infos p-2">
                        <h4 className="visite-title fs-5">
                            <small className="visite-hlo">{visite.loc_hlo} {agrement && ` / ${agrement} `}
                                <span className="text-muted fs-6">({
                                    config.group_visit[visite.type_bien].name === "Chambre d'hôtes" ?
                                        "Ch. hôtes" :
                                        config.group_visit[visite.type_bien].name
                                })</span>
                            </small>
                        </h4>
                        <p className="visite-details">
                            {bien?<small className="visite-nom-bien fw-bold">{bien.name}</small>:<span className="badge rounded-pill bg-danger">Ce bien est absent de Nomade</span>}
                            {props && props.map(prop => prop && 
                                <Fragment key={prop.uid}>
                                    <small className="d-block fw-bold">{prop.gender?getGenderName(prop.gender):''} {prop.firstname?prop.firstname:''} {prop.lastname?prop.lastname:''} {/*<span className="text-muted fw-light">({prop.type?prop.type:''})</span>*/}</small>
                                </Fragment>
                            )}
                            {<span className="text-muted">
                                <small className="text-nowrap">
                                    <span>Créée le {dateCreate}</span> par
                                    <span> {visite.user_firstname ? visite.user_firstname : ''} {visite.user_lastname ? visite.user_lastname : ''}</span>
                                </small>
                                <small>Modifiée <span>le {dateUpdate}</span></small>
                                {dateScelle && <small><span>Scellée le {dateScelle}</span></small>}
                            </span>}
                        </p>
                        <p className="d-lg-none">
                            <small className="d-flex gap-2">
                                <span>{cles} {cles > 1 ? "clés" : "clé" }</span>
                                <span>|</span>
                                <span>{typeVisite}</span>
                                <span>|</span>
                                <span>{date}</span>
                            </small>
                        </p>
                    </div>
                </div>
                <div className="d-lg-flex d-none">
                    <div className="visite-cles p-2">
                        {bien && <>
                            {bien.type === "inso" && cles ===5 ? <span className="badge rounded-pill bg-success"><i className="icon icon-check"></i></span>:''}
                            {bien.type === "inso" && cles !== 5 ? <span className="badge rounded-pill bg-warning"><i className="icon icon-ios-close"></i></span>:''}
                            {(bien.type !== "inso" && cles !== Infinity) && <small>{cles}</small>}
                        </>}
                    </div>
                    <div className="visite-type p-2">
                        {(visite.type_visite !== 'vstarch' && canUpdateVisite(visite) && forVisite) ? <VisiteTypeButton visite={visite} /> : typeVisite}
                    </div>
                    <div className="date p-2">
                        <small>{date}</small>
                    </div>
                </div>
            </div>
            {visite.type_visite !== 'vstarch' &&
                <div>
                    {!hasContent && <p className="pe-3 mb-0 text-danger"><small>Visite corrompue</small></p>}
                    <div className="visite-actions d-flex">

                        {(forVisite && hasContent && bien) &&
                        <div className="visite-action p-2">
                            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipVoir}>
                                <Link className="btn btn-outline-success" to={`/visit/${visite.uid}`} title="Voir cette visite"><i className="icon icon-eye"></i></Link>
                            </OverlayTrigger>
                        </div>}

                        {(visite.date_visite > config.autorisations.duplication.date_visite) && (forVisite && hasContent) && bien &&
                        <ErrorBoundary>
                            <div className="visite-action p-2">
                                <ButtonDuplicateVisite visite={visite} />
                            </div>
                        </ErrorBoundary>}

                        {(visite.date_visite < config.autorisations.duplication.date_visite) && (forVisite && hasContent) && bien &&
                            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipDuplicateToLate}>
                                <div className="visite-action p-2">
                                    <div className="btn btn-outline-secondary disabled"><i className="fs-5 icon icon-copy"></i></div>
                                </div>
                            </OverlayTrigger>
                        }

                        { (canUpdateVisite(visite) && forVisite && bien) &&
                            <div className="visite-action p-2">
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipEdit}>
                                    <Link
                                        className={`btn ${hasContent ? "btn-outline-info" : "btn-outline-secondary disabled"}`}
                                        to={`/visit-update/${visite.uid}`} title="Modifier cette visite">
                                        <i className="icon icon-pencil"></i>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        }

                        {
                            (window.navigator.onLine && isNaN(visite.uid)) &&
                            <div className="visite-action p-2">
                                <Button variant="outline-info" as="a" disabled>
                                    <Spinner animation="border" size="sm" role="status" as="span" aria-hidden="true" />
                                </Button>
                            </div>
                        }

                        {canDeleteVisite(visite) &&
                            <div className="visite-action p-2"><ButtonDeleteVisite visite={visite} /></div>
                        }
                    </div>
                    <div className="visite-actions d-flex">
                        { (canUpdateVisite(visite) && (forVisite && hasContent)) &&
                            <div className="visite-action p-2">
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipCompteRendu}>
                                    <Button
                                        variant={(visite.cpt_rendu && visite.cpt_rendu !== '<p></p>') ? "success" : "outline-secondary"}
                                        onClick={openCommentModal} title="Éditer le compte-rendu de cette visite">
                                        <i className="icon icon-text"></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        }

                        {(forVisite && hasContent && bien) &&
                            <ErrorBoundary>
                                <div className="visite-action p-2">
                                    {isMobile ?
                                        <VisitePdf visiteUid={visite.uid} isMobile={isMobile} /> :
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipPDF}>
                                            <Link className="btn btn-outline-danger" to={`/compte-rendu/${visite.uid}`} title="Voir le dossier compte-rendu PDF"><i className="icon icon-pdf"></i></Link>
                                        </OverlayTrigger>
                                    }
                                </div>
                            </ErrorBoundary>
                        }

                        {(bien && bien.type && bien.type === "inso") &&
                            <div className="visite-action p-2"><ButtonFiles visite={visite} /></div>
                        }

                    </div>
                </div>
            }

            {visite.type_visite === 'vstarch' && pageArchiveExists && (
                <div>
                    <a href={`${config.apiData.domain}/vstarch/visite-${vuid}.html`} target="_blank" rel="noopener noreferrer">
                        Consulter l'archive
                    </a>
                </div>
            )}
            {visite.type_visite === 'vstarch' && isOnline && !pageArchiveExists && <div>L'archive n'existe pas.</div>}
            {visite.type_visite === 'vstarch' && !isOnline && <div>Archive consultable en ligne.</div>}

            {showCommentModal && <ModalComment show={showCommentModal} onHide={onCommentModalclose} visite={visite} />}
        </div>
    );

}

export default VisiteItem;
